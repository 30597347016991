<template>
  <div>
    <div class='row mt-25'>
      <div class='col s12 m3'>
          <label>Period</label><br>
          <select v-model='period' class='browser-default mt-10'>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="this_week">This week</option>
            <option value="last_week">Last week</option>
            <option value="this_month">This month</option>
            <option value="last_month">Last month</option>
            <option value="custom">Custom</option>
          </select>
      </div>
      <div class='col s12 m2'>
          <label>From Date</label><br>
        <input v-model.lazy='start_date' @change="changeEvent" class="datepicker" name='start_date'>
      </div>
      <div class='col s12 m2'>
          <label>To Date</label><br>
        <input v-model.lazy='end_date' class='datepicker' name='end_date'>
      </div>
      <div class='col s12 m3'>
        <label>Filter</label><br>
        <select v-model='filter' @change="getReport" class='browser-default mt-10'>
          <option value="all">Cards & Checks</option>
          <option value="overall">Cards & Checks (With non paid)</option>
          <option value="cards">Cards</option>
          <option value="checks">Checks</option>
          <option value="cash">Cash</option>
        </select>
      </div>
    </div>

    <div class="row mt-25">
      <div class="col s12 button-container">

        <div style="display: flex; flex-direction: column;">
          <a :href="csvLink" target="_blank" class="btn">
            Download
            <span v-if="splitBySub || splitByGroup">XLSX</span>
            <span v-else>CSV</span>
          </a>
        </div>

        <div style="display: flex; flex-direction: column; margin-left: 10px;">
          <select class="browser-default" v-model="selector">
            <option value="csv" selected>CSV Report</option>
            <option value="splitSubAcc">Split the download by sub-account into a multi-page XLSX file</option>
            <option value="splitGroup">Split the download by groups into a multi-page XLSX file</option>
          </select>
        </div>
      </div>
    </div>

    <table class='table striped'>
      <thead>
        <tr>
          <th>Initiated</th>
          <th>Paid</th>
          <th>ID</th>
          <th>Auth Code</th>
          <th>Account</th>
          <th>Type</th>
          <th>Payment Source</th>
          <th>Note</th>
          <th>Invoice #</th>
          <th>Net Amount, $</th>
          <th>Fee, $</th>
          <th>Total Charged, $</th>
          <th>Job total, $</th>
          <th>Tip, $</th>
          <th>State</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='transaction in transactions'>
          <td>{{transaction.attributes.show_initiated_at}}</td>
          <td>{{transaction.attributes.show_paid_at}}</td>
          <td>{{transaction.attributes.id}}</td>
          <td>{{transaction.attributes.auth_code}}</td>
          <td>{{transaction.attributes.business_name}}</td>
          <td>{{transaction.attributes.human_readable_type}}</td>
          <td>{{transaction.attributes.source_on_api}}</td>
          <td :class='transaction.attributes.amount_color'>{{transaction.attributes.note}}</td>
          <td :class='transaction.attributes.amount_color'>{{transaction.attributes.invoice}}</td>
          <td :class='transaction.attributes.amount_color'>{{(transaction.attributes.surcharge_eligible ? transaction.attributes.amount : transaction.attributes.gross_amount) | currency }}</td>
          <td>{{transaction.attributes.fee | currency}}</td>
          <td>{{(transaction.attributes.surcharge_eligible ? transaction.attributes.gross_amount : transaction.attributes.amount) | currency }}</td>
          <td>{{transaction.attributes.job_total | currency }}</td>
          <td>{{transaction.attributes.tip_amount | currency }}</td>
          <td>{{transaction.attributes.show_state}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  export default {
    created() {
      this.period = 'this_week'
    },
    props: ['userId', 'tipState', 'taxState', 'surchargeEligible'],
    data() {
      return {
        period: null,
        filter: "all",
        transactions: [],
        start_date: null,
        end_date: null,
        selector: 'csv',
      }
    },
    methods: {
      changeEvent() {
        this.period = "custom"
      },
      getReport() {
        this.$http.get('/api/v1/vue/reports', { params: { start_date: this.start_date, end_date: this.end_date, filter: this.filter } }).then(response => {
          this.transactions = response.body.data
        })
      },
      newVal(event) {
        console.log(event)
      }
    },
    watch: {
      period: function(val) {
        switch(val) {
          case 'today':
            this.start_date = this.$moment().startOf('day').format("MMM Do YYYY")
            this.end_date = this.$moment().format("MMM Do YYYY")
            break;
          case 'yesterday':
            this.start_date = this.$moment().subtract(1, 'days').startOf('day').format("MMM Do YYYY")
            this.end_date = this.$moment().subtract(1, 'days').endOf('day').format("MMM Do YYYY")
            break;
          case 'this_week':
            this.start_date = this.$moment().startOf('week').format("MMM Do YYYY")
            this.end_date = this.$moment().format("MMM Do YYYY")
            break;
          case 'last_week':
            this.start_date = this.$moment().subtract(7, 'days').startOf('week').format("MMM Do YYYY")
            this.end_date = this.$moment().subtract(7, 'days').endOf('week').format("MMM Do YYYY")
            break;
          case 'this_month':
            this.start_date = this.$moment().startOf('month').format("MMM Do YYYY")
            this.end_date = this.$moment().format("MMM Do YYYY")
            break;
          case 'last_month':
            this.start_date = this.$moment().subtract(30, 'days').startOf('month').format("MMM Do YYYY")
            this.end_date = this.$moment().subtract(30, 'days').endOf('month').format("MMM Do YYYY")
            break;
          case 'last_3_monthes':
            this.start_date = this.$moment().subtract(90, 'days').startOf('month').format("MMM Do YYYY")
            this.end_date = this.$moment().format("MMM Do YYYY")
            break;
        }
      },
      periodChanged: function() {
        this.getReport()
      }
    },
    computed: {
      periodChanged: function() {
        return [this.start_date,this.end_date]
      },
      csvLink: function() {
        return '/api/v1/vue/reports.csv?start_date=' + encodeURI(this.start_date) + '&end_date=' + encodeURI(this.end_date) + '&filter=' + this.filter + '&split_by_sub=' + this.splitBySub + '&split_by_group=' + this.splitByGroup
      },
      splitByGroup: function() {
        return this.selector === 'splitGroup'
      },
      splitBySub: function() {
        return this.selector === 'splitSubAcc'
      }
    }
  }
</script>
<style scoped>
  .button-container {
    display: flex;
    align-items: center;
  }
</style>
