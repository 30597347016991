<template>
  <div>
    <div class="row">
      <div class="col s12 m6">
        <pay-setting-switch
          :gid="gid"
          label="Use Default Refund and Void Settings:"
          setting-field="useDefaultRefundAndVoidSettings"
          :initial-state="defaultRefundAndVoidSettings"
          mutation-attribute="useDefaultRefundAndVoidSettings"
          v-model="useDefaultRefundAndVoidSettingsState"
          >
        </pay-setting-switch>
      </div>
      <div class="col s12 m6" v-show="!useDefaultRefundAndVoidSettingsState">
        <pay-setting-switch
          :gid="gid"
          label="Allow Refunds:"
          setting-field="allowRefunds"
          :initial-state="allowRefunds"
          mutation-attribute="allowRefunds"
          >
        </pay-setting-switch>
        <pay-setting-switch
          class="mt-5"
          :gid="gid"
          label="Allow Voids:"
          setting-field="allowVoids"
          :initial-state="allowVoids"
          mutation-attribute="allowVoids"
          >
        </pay-setting-switch>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['gid', 'defaultRefundAndVoidSettings', 'allowVoids', 'allowRefunds'],
  data() {
    return {
      useDefaultRefundAndVoidSettingsState: this.defaultRefundAndVoidSettings,
      disabled: false,
      endPoint: '/api/v1/app/merchant/ach_transactions/'
    }
  },
  methods: {
  }
}
</script>
