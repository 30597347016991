<template>
  <div>
    <div class="row">
      <div class="input-field col s12 m6">
        <i class="fas fa-dollar-sign prefix grey-text"></i>
        <input 
          name="amount"
          id="amount"
          autofocus
          v-model='amount'
          step="0.01"
          type="number"
          inputmode="decimal"
          v-validate="'min_value:0.51|required'"
          class='inputText'
          lang="en-001">
          <span class='red-text small-font' v-show='errors.first("amount")'>{{errors.first("amount")}}</span>

        <label for="amount">Amount</label>
      </div>
      <div class="input-field col s12 m6">
        <i class="fas fa-sticky-note prefix grey-text"></i>
        <input id="note" name="note" type="text" v-model="note" ref="note">

        <label for="note">Note (Optional)</label>
      </div>
    </div>

    <slot :update-token="updateToken"></slot>

    <section class="card-panel">
      <div class="bold-font">Recurring schedule: </div>
      <div class="row">
        <div class="col s12 m3 mt-10">
          <select v-model="frequency" class="browser-default" @change="on=[]">
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
        </div>
        <div class="col s6 m3 mt-10">
          Every:
          <input v-model="each" type="number" style="width: 30px; margin-top: -30px; margin-left: 10px">
           {{placeholders.frequency}}
        </div>
        <div class="col s6 m3 mt-10 input-field">
          <input name="start-date" type="date" v-model.lazy="startDate" class="datepickerX">
          <label for="start-date">Start date</label>
        </div>
        <div class="col s12 m3" style="margin-top: -10px">
          <label for="start-date">Repeat</label>
          <select v-model="count" class="browser-default">
            <option :value="null">Unlimited</option>
            <option :value="i" v-for="i in 12">{{i}}</option>
          </select>
        </div>
      </div>
      <div class="center">
        <span class="on" :class="activeOn(item[0])" @click="addOn(item[0])" v-for="item in placeholders.on">{{item[1]}}</span>
      </div>

      <div class="center emerald mt-10" v-if="!recurringStringError">
        Charge {{amount | currency}} {{recurringString}}<span v-if="totalAmountForRepeat"> for a total of {{totalAmountForRepeat | currency}}</span>, 
        starting on {{startDate}}
      </div>

      <div class="center red-text mt-10" v-else>
        {{recurringStringError}}
      </div>
    </section>

    <button @click="charge" v-show="!showPending" class="btn-large wide-btn mt-50" :disabled="disableSubmit">
      Schedule<span v-show='amount'></span>
    </button>
    <center v-show='showPending' class='grey-text mt-25'>
      <i class="fas fa-spinner fa-spin"></i> Processing...
    </center>
  </div>
</template>
<script>
  import gql from 'graphql-tag'

  export default {
    props: ["userId", "cardId"],
    apollo: {
      recurringString: {
        fetchPolicy: "no-cache",
        query: gql`query($frequency: String!, $each: Int!, $on: [Int!], $count: Int) {
          merchant { recurringString(frequency: $frequency, each: $each, on: $on, count: $count) }
        }`,
        variables() {
          return {
            frequency: this.frequency,
            each: parseInt(this.each),
            on: this.on,
            count: parseInt(this.count)
          }
        },
        update(data) {
          this.recurringStringError = null
          return data.merchant.recurringString
        },
        error(error) {
          this.recurringStringError = "Invalid recurring string"
        }
      }
    },
    data() {
      return {
        amount: null,
        showPending: false,
        note: null,
        frequency: "weekly",
        each: 1,
        startDate: (new Date()).toISOString().split('T')[0],
        on: [],
        recurringString: null,
        recurringStringError: null,
        cardToken: null,
        count: null
      }
    },
    methods: {
      charge() {
        this.showPending = true
        this.$apollo.mutate({
          mutation: gql`mutation (
            $amount: Float!,
            $note: String,
            $start_time: ISO8601Date!,
            $recurring_params: RecurringAttributes!,
            $card_id: String!
          ) {
            createPaymentEventForCard(input: 
              {
                amount: $amount,
                note: $note,
                startTime: $start_time,
                recurringParams: $recurring_params,
                cardId: $card_id
              }) {
              message errors
            }
          }`,
          // Parameters
          variables: {amount: parseFloat(this.amount), note: this.note, start_time: this.startDate, recurring_params: this.recurringParams, card_id: this.cardId}
        }).then(result => {
          if(result.data.createPaymentEventForCard.errors.length > 0) {
            this.showPending = false
            this.notifyError(result.data.createPaymentEventForCard.errors[0])
          } else {
            this.notifySuccess(result.data.createPaymentEventForCard.message)
            Turbolinks.visit("/merchant/payment_events")
          }
        }, error => {
          this.showPending = false
          this.gqlErrorHandler(error)
        })
      },
      addOn(item) {
        if(this.on.includes(item)) {
          this.on = this.on.filter(e => e !== item)
        } else {
          this.on.push(item)
        }
      },
      activeOn(item) {
        if(this.on.includes(item)) { return "activeOn" }
      },
      updateToken(token) {
        this.cardToken = token.token
      }
    },
    computed: {
      placeholders() {
        switch(this.frequency) {
        case("weekly"):
          return {
            frequency: "week",
            on: [[0, "Sun"], [1, "Mon"], [2, "Tue"], [3, "Wed"], [4, "Thu"], [5, "Fri"], [6, "Sat"]]
          }
          break;
        case("monthly"):
          return {
            frequency: "month",
            on: Array(31).fill(1).map((x, y) => [x + y, x + y])
          }
          break;
        case("yearly"):
          return {
            frequency: "year",
            on: Array(31).fill(1).map((x, y) => [x + y, x + y])
          }
          break;
        }
      },
      disableSubmit() {
        if(this.amount > 0) {
          return false
        } else {
          return true
        }
      },
      recurringParams() {
        return {
          frequency: this.frequency,
          each: this.each,
          on: this.on,
          count: this.count
        }
      },
      cardParams() {
        return {
          token: this.cardToken.token
        }
      },
      totalAmountForRepeat() {
        if(this.count && this.amount) {
          return this.amount * this.count
        }
      }
    }
  }
</script>
<style scoped>
  .on {
    border: 2px solid #00ab8e;
    height: 30px;
    cursor: pointer;
    width: 45px;
    display: inline-block;
    margin-left: 2px;
    margin-top: 2px;
    color: #00ab8e;
  }
  .activeOn {
    color: white;
    background-color: #00ab8e;
  }
</style>
