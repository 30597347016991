<template>
  <div>
    <div class="btn-large wide-btn rounded-btn mt-25" @click="payAch" v-if="!showOnePaymentOptionForm && !showForm">
      <span style='margin-right: 10px'>{{$t("sendBill.payWithAch")}}</span>
    </div>

    <section class="main-form" v-else>
      <section class='mt-10 grey-text center' v-if='showTips'>
        <tips :qid="merchantQidParam" :tips-updated="tipsUpdated"></tips>
      </section>
      <div class="row mt-10">
        <div class="input-field col s12 m6">
          <i class="fas fa-random prefix grey-text"></i>
          <!-- Updated input field with corrected bindings -->
          <input
            v-model="routing"
            id="routing"
            type="text"
            class="validate"
            :class="{'invalid': errors.has('routing')}"
            v-validate="'required|regex:^[0-9]+$'"
            name="routing"
            @blur="validate('routing')">

          <label for="routing" :class="{'active': routing}">{{$t("sendBill.routing")}}</label>
          <!-- Corrected data-error and data-success bindings -->
          <span class="helper-text" :data-error="errors.first('routing')"></span>
        </div>
        <div class="input-field col s12 m6">
          <i class="fas fa-hashtag prefix grey-text"></i>
          <input v-model="accountNumber" id="accountNumber" type="text" class="validate" v-validate="'required|regex:^[0-9]+$'" name="Account Number">
          <label for="accountNumber">{{$t("sendBill.accountNumber")}}</label>
          <span class="red-text" v-show="errors.has('Account Number')">{{ errors.first('Account Number') }}</span>
        </div>
      </div>

      <div class="row">
        <div class="input-field col s12 m6">
          <i class="fas fa-user prefix grey-text"></i>
          <input v-model="firstName" id="firstName" type="text" class="validate">
          <label for="firstName">{{$t("sendBill.firstName")}}</label>
        </div>
        <div class="input-field col s12 m6">
          <input v-model="lastName" id="lastName" type="text" class="validate">
          <label for="lastName">{{$t("sendBill.lastName")}}</label>
        </div>
      </div>

      <button class="btn-large wide-btn mt-10" type="submit" name="action" @click="submit" :disabled="!submittable" v-if="!processing">
        {{$t("sendBill.pay")}} {{grossAmount | currency}}
      </button>

      <div v-else class='grey-text center mt-10'>
        <i class="fas fa-spinner fa-spin grey-text"></i> 
        Processing ...
      </div>

      <div style="display: flex; align-items: center;" class="red-text mt-10" v-if="gVerifyError && gVerifyChecked && !processing">
        <i class="fas fa-info-circle" style="margin-right: 10px; font-size: 18px;"></i>
        <span>
          <strong>Notice:</strong> {{gVerifyError}} But you can still proceed.
        </span>
      </div>

      <div class="center grey-text mt-5" v-if="feeAmount > 0">
        {{$t("sendBill.grossAmount")}}: <b>{{grossAmount | currency}}</b> |  
        {{$t("sendBill.nonCashAdj")}}: <b>{{feeAmount | currency}}</b>
      </div>
      <div class="grey-text small-font center">Agree to be charged up to the amount on the contract</div>
    </section>
  </div>
</template>
<script>
  import { useBaseStore } from "./base_store"
  import { toRefs } from '@vue/composition-api'
  import tips from "../../tips"

  export default {
    components: { tips },
    setup: () => ({ ...toRefs(useBaseStore()) }),
    created() {
      if(this.showOnePaymentOptionForm) {
        this.updateAmounts()
      }
    },
    props: [
      "merchantQidParam",
      "amountNumberParam",
      "showTips",
      "linkOrderId",
      "showVote",
      "showReview",
      "showFeedbackHub",
      "useNpsVote",
      "showOnePaymentOptionForm",
      "merchantVotesUrl",
      "performGverify"
    ],
    data() {
      return {
        showForm: false,
        routing: null,
        accountNumber: null,
        firstName: null,
        lastName: null,
        processing: false,
        grossAmount: 0,
        feeAmount: 0,
        selectedTip: 0,
        tipCustomAmount: 0,
        lastGrossCalcRequestId: 0,
        gVerifyChecked: false,
        gVerifyError: null
      }
    },
    methods: {
      payAch() {
        this.showForm = true
        this.selectedFlow = ['ach']
        this.updateAmounts()
      },
      processGverify() {
        this.$http.post("/api/v1/public/adyen_gverify", this.gVerifyParams).then(() => {
          this.gVerifyChecked = true
          this.processAch()
        }, error => {
          this.gVerifyChecked = true
          if(error.status == 422) {
            this.gVerifyError = error.body.errors[0]
          } 
        })
      },
      processAch() {
        this.processing = true

        if(this.tipAmount < 20 || confirm(`Confirm ${this.tipAmountFormatted} tip amount`)){
          this.disableSubmit = true
          this.showPending = true
          this.resubmitFlow = true

          this.$http.post("/api/v1/app/client/link_orders/ach_transactions", this.params).then(response => {
            this.transactionId = response.body.transaction_id

            Turbolinks.visit(
              "/a/thank_you?transaction_id=" +
                this.transactionId +
                "&url=" + this.merchantVotesUrl +
                "&locale=" + this.$i18n.locale +
                "&show_vote=" + this.showVote +
                "&show_review=" + this.showReview +
                "&show_feedback_hub=" + this.showFeedbackHub +
                "&use_nps_vote=" + this.useNpsVote
            )
          }, error => {
            this.errorHandler(error)
            this.processing = false
            this.gVerifyError = null

            if(error.status == 422) {
              this.errorMessage = error.body.error_messages[0]
            } else {
              this.errorMessage = "Something went wrong"
            }
          })
        }
      },
      async updateAmounts() {
        this.lastGrossCalcRequestId++

        const requestId = this.lastGrossCalcRequestId
        const response = await this.$http.get("/api/v1/vue/gross_ach_calculations", {
          params: {
            amount: this.amountNumberParam,
            tip_percent: this.selectedTip,
            tip_custom_amount: this.tipCustomAmount,
            qid: this.merchantQidParam,
          }
        });

        if (requestId === this.lastGrossCalcRequestId) {
          this.grossAmount = response.body.gross_amount;
          this.feeAmount = response.body.fee;
          this.withTipAmount = response.body.amount_with_tip;
          this.tipAmount = response.body.tip_amount;
          this.tipAmountFormatted = response.body.tip_amount_formatted;
        }
      },
      tipsUpdated(selectedTip, tipCustomAmount) {
        this.selectedTip = selectedTip
        this.tipCustomAmount = tipCustomAmount
        this.updateAmounts()
      },
      validate(field) {
        this.$validator.validate(field)
      }
    },
    computed: {
      params() {
        return {
          link_order_id: this.linkOrderId,
          routing: this.routing,
          account_number: this.accountNumber,
          first_name: this.firstName,
          last_name: this.lastName,
          tip_percent: this.selectedTip,
          tip_amount: this.tipCustomAmount
        }
      },
      gVerifyParams() {
        return {
          qid: this.merchantQidParam,
          routing: this.routing,
          account_number: this.accountNumber,
          first_name: this.firstName,
          last_name: this.lastName
        }
      },
      submittable() {
        // Check if none of the properties are null or undefined
        return this.routing !== null && this.routing !== undefined &&
               this.accountNumber !== null && this.accountNumber !== undefined &&
               this.firstName !== null && this.firstName !== undefined &&
               this.lastName !== null && this.lastName !== undefined;
      },
      submit() {
        if (this.performGverify && !this.gVerifyChecked) {
          return this.processGverify;
        }
        return this.processAch;
      }
    } 
  }
</script>
