<template>
  <div>
    <section class="main-form" v-show="!showItemForm">
      <div class="row" v-if="items.length == 0">
        <div class="input-field col s12 m6">
          <i class="fas fa-dollar-sign prefix grey-text"></i>
          <input
            name="amount"
            id="amount"
            @input="updateAmounts"
            autofocus
            v-model='amount'
            step="0.01"
            type="text" 
            inputmode="decimal"
            v-validate="'min_value:0.01|required'"
            class='inputText'
            lang="en-001">

          <label for="amount">Amount</label>
        </div>
      </div>
      <div v-show="items.length > 0">
        <div class="col s12 m6">
          <span class="items-amount bold-font emerald">{{amount | currency}}</span>
        </div>
        <div class="col s12 m6">
          <show-items :items="items" :items-updated="itemsUpdated"></show-items>
        </div>
      </div>

      <div class="row">
        <div class="input-field col s12 m6">
          <i class="fas fa-random prefix grey-text"></i>
          <input v-model="routing" id="routing" type="text" class="validate" v-validate="'required|regex:^[0-9]+$'" name="routing">
          <label for="routing">Routing</label>
          <span class="red-text" v-show="errors.has('routing')">{{ errors.first('routing') }}</span>
        </div>
        <div class="input-field col s12 m6">
          <i class="fas fa-hashtag prefix grey-text"></i>
          <input v-model="accountNumber" id="accountNumber" type="text" class="validate" v-validate="'required|regex:^[0-9]+$'" name="Account Number">
          <label for="accountNumber">Account Number</label>
          <span class="red-text" v-show="errors.has('Account Number')">{{ errors.first('Account Number') }}</span>
        </div>
      </div>

      <div class="row">
        <div class="input-field col s12" :class="{'m6': requireInvoiceConfirmation, 'm12': !requireInvoiceConfirmation}">
          <i class="fas fa-file-invoice prefix grey-text"></i>
          <input v-model="invoice" id="invoice" type="text" class="validate" ref="invoice">
          <label for="invoice">Invoice</label>
        </div>
        <div class="input-field col s12 m6" v-if="requireInvoiceConfirmation">
          <i class="fas fa-file-invoice prefix grey-text"></i>
          <input name="invoice-cfm" id="invoice-cfm" type="text" v-validate="{required: invoice.length > 0, confirmed: 'invoice'}">

          <label for="invoice-cfm">Invoice (confirmation)</label>
          <span v-show="errors.has('invoice-cfm')" class="red-text">Invoice numbers do not match</span>
        </div>
        <div class="input-field col s12 m4">
          <i class="fas fa-user prefix grey-text"></i>
          <input v-model="firstName" id="firstName" type="text" class="validate">
          <label for="firstName">First Name</label>
        </div>
        <div class="input-field col s12 m4">
          <input v-model="lastName" id="lastName" type="text" class="validate">
          <label for="lastName">Last Name</label>
        </div>
        <div class="input-field col s12 m4">
          <i class="fas fa-sticky-note prefix grey-text"></i>
          <input v-model="note" id="note" type="text" class="validate">
          <label for="note">Note</label>
        </div>
      </div>

      <button class="btn btn-small outlined-btn" @click="showItemForm=true" v-show='!showPending'>
        <i class="fas fa-plus"></i>

        Add Item
      </button>

      <center>
        <section class='mt-10' v-if='showTips'>
          <tips :qid="qid" :tips-updated="tipsUpdated"></tips>
        </section>
      </center>

      <button class="btn-large wide-btn mt-10" type="submit" name="action" @click="submit" :disabled="!submittable" v-if="!processing">
        Charge {{grossAmount | currency}}
      </button>

      <div v-else class='grey-text center mt-10'>
        <i class="fas fa-spinner fa-spin grey-text"></i> 
        Processing ...
      </div>

      <div style="display: flex; align-items: center;" class="red-text mt-10" v-if="gVerifyError && gVerifyChecked && !processing">
        <i class="fas fa-info-circle" style="margin-right: 10px; font-size: 18px;"></i>
        <span>
          <strong>Notice:</strong> {{gVerifyError}} But you can still proceed.
        </span>
      </div>

      <div class="center grey-text mt-5" v-if="feeAmount > 0">
        Gross amount: <b>{{grossAmount | currency}}</b> |  
        Processing fee: <b>{{feeAmount | currency}}</b>
      </div>
    </section>

    <items
      v-if="showItemForm"
      :items="items"
      :items-updated="itemsUpdated"
      :set-invoice="invoice"
      :set-note="note"
      :set-amount="items.length > 0 ? null : amount"
      :invoice-label="invoiceLabel"
      :require-invoice-confirmation="requireInvoiceConfirmation"
      @close="showItemForm=false">

    </items>
  </div>
</template>

<script>
  import gql from 'graphql-tag'
  import tips from "../../tips"
  import items from '../generic_cards/items'
  import showItems from '../generic_cards/show_items'

  export default {
    components: {tips, items, showItems},
    props: ['qid', 'showTips', 'requireInvoiceField', 'requireInvoiceConfirmation', 'performGverify'],
    data() {
      return {
        amount: null,
        accountNumber: null,
        routing: null,
        firstName: null,
        lastName: null,
        note: null,
        invoice: "",
        items: [],
        showItemForm: false,
        showPending: false,
        grossAmount: 0,
        feeAmount: 0,
        withTipAmount: 0,
        lastGrossCalcRequestId: 0,
        processing: false,
        gVerifyChecked: false,
        gVerifyError: null
      };
    },
    methods: {
      updateAmounts() {
        this.lastGrossCalcRequestId++
        let requestId = this.lastGrossCalcRequestId
        this.$apollo.query({
          query: gql`query ($amount: Float!, $tipPercent: Int, $tipCustomAmount: Float) {
            merchant {
              grossAchAmountCalc(amount: $amount, tipPercent: $tipPercent, tipCustomAmount: $tipCustomAmount) {
                grossAmount
                tip
                amountWithTip
                fee
              }
            }
          }`,
          variables: {
            amount: parseFloat(this.amount) || 0,
            tipPercent: parseInt(this.selectedTip),
            tipCustomAmount: parseFloat(this.tipCustomAmount)
          }
        }).then(response => {
          if(requestId == this.lastGrossCalcRequestId) {
            this.grossAmount = response.data.merchant.grossAchAmountCalc.grossAmount
            this.feeAmount = response.data.merchant.grossAchAmountCalc.fee
            this.withTipAmount = response.data.merchant.grossAchAmountCalc.amountWithTip
          }
        })
      },
      tipsUpdated(selectedTip, tipCustomAmount) {
        this.selectedTip = selectedTip
        this.tipCustomAmount = tipCustomAmount
        this.updateAmounts()
      },
      itemsUpdated(amount) {
        this.amount = amount
        this.invoice = ""
        this.note = ""
        this.updateAmounts()
      },
      processGverify() {
        this.processing = true
        this.$apollo.mutate({
          mutation: gql`
            mutation($account_number: String!, $routing: String!, $first_name: String!, $last_name: String!) {
              adyenGverify(input: {accountNumber: $account_number, routing: $routing, firstName: $first_name, lastName: $last_name}) {
                errors
              }
            }
          `,
          variables: {
            account_number: this.accountNumber,
            routing: this.routing,
            first_name: this.firstName,
            last_name: this.lastName
          }
        }).then(response => {
          if (response.data.adyenGverify.errors.length > 0) {
            this.processing = false;
            this.gVerifyChecked = true;
            this.gVerifyError = response.data.adyenGverify.errors[0];
          } else {
            this.gVerifyChecked = true;
            this.processAch();
          }
        }).catch(error => {
          this.processing = false;
          this.gVerifyChecked = true;
          console.error('Error submitting transaction:', error);
        });
      },
      processAch() {
        this.processing = true
        this.$apollo.mutate({
          mutation: gql`
            mutation($amount: Float!, $account_number: String!, $routing: String!, $first_name: String!, $last_name: String!, $note: String, $invoice: String, $tip_percent: Int, $tip_amount: Float, $items: [ItemAttributes!]) {
              chargeAchDirect(input: {amount: $amount, accountNumber: $account_number, routing: $routing, firstName: $first_name, lastName: $last_name, note: $note, invoice: $invoice, items: $items, tipPercent: $tip_percent, tipAmount: $tip_amount}) {
                transaction {
                  authCode
                  deprecatedId
                }
                errors
              }
            }
          `,
          variables: {
            amount: parseFloat(this.amount),
            account_number: this.accountNumber,
            routing: this.routing,
            first_name: this.firstName,
            last_name: this.lastName,
            note: this.note,
            invoice: this.invoice,
            tip_percent: parseInt(this.selectedTip),
            tip_amount: parseFloat(this.tipCustomAmount),
            items: this.items
          }
        }).then(response => {
          if (response.data.chargeAchDirect.errors.length > 0) {
            this.processing = false;
            this.notifyError(response.data.chargeAchDirect.errors[0]);
          } else {
            window.location.href = '/client/ach_transactions/' + response.data.chargeAchDirect.transaction.deprecatedId
          }
        }).catch(error => {
          this.processing = false;
          console.error('Error submitting transaction:', error);
          this.gqlErrorHandler(error)
        });
      }
    },
    computed: {
      paramsGrossCalc() {
        return {
          amount: this.amount,
          tip_percent: this.selectedTip,
          tip_custom_amount: this.tipCustomAmount,
          qid: this.qid
        }
      },
      invoiceLabel() {
        return this.requireInvoiceField ? "Invoice # (required)" : "Invoice # (optional)" 
      },
      submittable() {
        return (
          this.amount != null && 
          this.amount > 0 && 
          this.routing != null && this.routing !== "" && 
          this.accountNumber != null && this.accountNumber !== "" && 
          this.firstName != null && this.firstName !== "" && 
          this.lastName != null && this.lastName !== ""
        );
      },
      submit() {
        if (this.performGverify && !this.gVerifyChecked) {
          return this.processGverify;
        }
        return this.processAch;
      }
    }
  }
</script>
<style scoped>
  .items-amount {
    font-size: 3em !important;
  }
  .mb--10 {
    margin-bottom: -30px !important;
  }
</style>
